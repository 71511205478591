import { CSSProperties } from 'react';
import { SvgIcon } from '@mui/material';

type Props = {
  color?: string;
  size?: number | string;
  style?: CSSProperties;
};
export default function Ellipse({ color = '#121318', size = 64, style }: Props) {
  return (
    <SvgIcon viewBox="0 0 64 64" fill="none" style={style} sx={{ height: size, width: size }}>
      <path d="M38.4 46.9334H21.3333V64L38.4 46.9334Z" fill={color} />
      <path
        d="M38.4 12.8002L25.6 0.000156403V12.8002H4.26666L12.8 21.3335L4.26666 29.8668L21.3333 46.9335V29.8668H42.6667L59.7333 12.8002H38.4ZM42.2357 16.6402H50.4619L46.3488 20.7532L42.2357 16.6402Z"
        fill={color}
      />
    </SvgIcon>
  );
}
