import { Container, Typography, Button, Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from '../theme';

const Root = styled('div')({
  fontFamily: 'KH Teka, Helvetica, Arial, sans-serif',
  height: '100vh',
  backgroundColor: theme.palette.common.black,
  alignContent: 'center',
  display: 'flex',
});

export function ErrorMessage({
  error,
  componentStack,
  colors: {
    // Short syntax for green.light didn't work correctly with contained Button
    buttonColor = theme.palette.green.light,
    buttonText = 'common.black',
    buttonHover = 'textLight.primary',
    linkHover = 'green.main',
    text = 'textLight.primary',
  } = {},
}: {
  error: Error;
  componentStack: string | null;
  colors?: {
    buttonColor?: string;
    buttonText?: string;
    buttonHover?: string;
    linkHover?: string;
    text?: string;
  };
}) {
  const body = encodeURIComponent(
    [
      'What did you do when things went wrong?',
      '------------------------------- Error info (please do not edit) -------------------------------',
      `Message: ${error.toString()}`,
      `Component stack: ${componentStack}`,
    ].join('\n\n'),
  );
  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ color: text }} gutterBottom>
        Looks like a monkey got to the cables at Milkywire HQ...{' '}
        <span role="img" aria-label="monkey">
          🙈
        </span>
      </Typography>
      <Typography sx={{ color: text }} paragraph maxWidth="sm">
        Looks like something have happen that we didn&apos;t think of. If this continues to happen
        to you please write us a line to the{' '}
        <Button
          variant="text"
          component="a"
          href={`mailto:support@milkywire.com?body=${body}`}
          sx={{
            color: buttonColor,
            padding: 0,
            ':hover': { color: linkHover },
          }}
        >
          Milkywire support
        </Button>
        .
      </Typography>
      <Button
        href="/"
        variant="contained"
        size="large"
        color="green"
        sx={{
          background: buttonColor,
          color: buttonText,
          ':hover': { color: buttonHover },
        }}
      >
        Back to the start page
      </Button>
    </Box>
  );
}

export default function AppCrash({
  error,
  componentStack,
}: {
  error: Error;
  componentStack: string | null;
}) {
  return (
    <Root>
      <Container component="main" maxWidth="md">
        <Grid
          sx={{ height: '100vh' }}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage error={error} componentStack={componentStack} />
        </Grid>
      </Container>
    </Root>
  );
}
