import { Box, Button, Drawer, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';
import appConfig from '../config/config';
import ExternalLink from './Link/ExternalLink';
import GoogleAnalytics from './scripts/GoogleAnalytics';

/**
 * Tracking consent for GA, necessary for GDPR compliance
 */
const TrackingConsent = () => {
  const [cookies, setCookie] = useCookies();

  const showConsentBanner = !cookies.cookie_consent;

  const shouldTrack = cookies.cookie_consent == 'true' && appConfig.env === 'production';

  if (showConsentBanner) {
    return (
      <ConsentBanner
        setConsent={(value: boolean) => setCookie('cookie_consent', value, { path: '/' })}
      />
    );
  }

  if (shouldTrack) {
    return <GoogleAnalytics />;
  }

  return null;
};

const ConsentBanner = ({ setConsent }: { setConsent: (value: boolean) => void }) => {
  return (
    <Drawer
      open={true}
      anchor="bottom"
      hideBackdrop
      variant="permanent"
      PaperProps={{
        sx: {
          zIndex: 1300, // To be above the menu
        },
      }}
    >
      <Box
        sx={{
          px: 2,
          py: 3,
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'black',
        }}
      >
        <Box m={2}>
          <Typography color="white" variant="body2">
            We use cookies to offer you a better experience and analyze site traffic. By pressing
            &quot;Accept cookies&quot;, you consent to our use of cookies. By pressing
            &quot;Necessary cookies&quot;, we will only set the cookies which are necessary for the
            site to function correctly.
          </Typography>
          <Typography color="white" variant="body2">
            For more information, please see our
            <ExternalLink
              sx={{
                color: 'textLight.primary',
                textDecorationColor: 'white',
              }}
              href="http://www.milkywire.com/cookie-policy"
              underline="always"
            >
              {' cookie policy.'}
            </ExternalLink>
          </Typography>
        </Box>
        <Button
          variant="outlined"
          onClick={() => setConsent(false)}
          fullWidth
          sx={{
            maxWidth: 200,
            m: 1,
            color: 'green.light',
            borderColor: 'green.light',
            '&:hover': {
              color: 'green.main',
              borderColor: 'green.main',
            },
          }}
        >
          Necessary&nbsp;cookies
        </Button>
        <Button
          variant="contained"
          onClick={() => setConsent(true)}
          fullWidth
          sx={{
            maxWidth: 200,
            m: 1,
            backgroundColor: 'green.light',
            color: 'black',
            '&:hover': {
              backgroundColor: 'green.main',
            },
          }}
        >
          Accept&nbsp;cookies
        </Button>
      </Box>
    </Drawer>
  );
};

export default TrackingConsent;
