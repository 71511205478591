import { Entry } from 'contentful';
import { TypeBlogPostSkeleton, TypeProfileSkeleton } from '../../@types/generated';
import { LOCALES } from '../lib/constants';
import {
  AccountingTypes,
  ContentTypes,
  PartnersTypes,
  ResponseData,
  UsersTypes,
} from '../lib/galaxyClient';

export enum UserStatus {
  ANONYMOUS = 'ANONYMOUS',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  PENDING = 'PENDING',
}

type UserRoles =
  | 'ADMIN'
  | 'CONTENTFUL'
  | 'IMPACTER'
  | 'TEST'
  | 'VALIDATED'
  | 'ANONYMOUS'
  | 'PARTNER_ADMIN'
  | 'PARTNER_API';

export type CurrentUser = ResponseData<UsersTypes, '/v2/me/', 'get'>;
export type User = ResponseData<UsersTypes, '/v2/users/{userId}', 'get'>;

export type Partner = ResponseData<PartnersTypes, '/v2/partners/{id}', 'get'>;

export type PartnerInvite = {
  email: string;
  firstName: string;
  isAdminInvite: boolean;
};

export type PartnerInvoiceStatus = PartnerInvoice['status'];

export type PartnerInvoiceItem = {
  invoiceId?: number;
  paymentRequestId?: string;
  amount: number;
  amountInCents?: number;
  createdAt: string;
  partnerReferences?: {
    id?: string;
    timestamp?: string;
    userId?: string;
  };
};

export type PartnerInvoice = ResponseData<
  AccountingTypes,
  '/v2/partners/{partnerId}/invoices/{id}',
  'get'
>;

type DonationSplitWithImpacterId = {
  amount: number;
  amountInCents: number;
  impacterId: Impacter['id'];
};

type DonationSplitWithCategoryId = {
  amount: number;
  amountInCents: number;
  categoryId: Category['id'];
};

export type DonationSplit = DonationSplitWithCategoryId | DonationSplitWithImpacterId;

type Affiliate = {
  resourceType: string;
  id: string;
  title: string;
};

type Card = {
  brand: string;
  month: number;
  year: number;
  last4: string;
};

export type Currency = {
  code: string;
  name: string;
  symbol: string;
  cents: number;
  providers?: string[];
};

type Gift = {
  id: string;
  period: number;
  giverName: string;
};

type PendingSetupIntent = {
  status: string;
};

type PaymentMethod = {
  id: string;
  created: string;
  card: Card;
};

type Provider = {
  donationProgramName: string;
  donationProgramId: string;
  donationProgramType: string;
  donationProgramRequiresConfirmation: boolean;
  partnerId: string;
  partnerName: string;
  giftId: string;
  pendingSetupIntent?: PendingSetupIntent;
  paymentMethod: PaymentMethod;
  gift: Gift;
};

type InvoiceItemImpacter = {
  id: number;
  amount: number;
  amountInCents: number;
  project: Pick<Project, 'id' | 'amount' | 'currency'>;
};

type InvoiceItemCategory = {
  id: string;
  amount: number;
  amountInCents: number;
  project: Pick<Project, 'id' | 'amount' | 'currency'>;
};

type InvoiceItem = {
  id: number;
  amount: number;
  editedBy?: number;
  editedAt?: string;
  impacters?: InvoiceItemImpacter[];
  categories: InvoiceItemCategory[];
};

export type Invoice = {
  id: number;
  userId: User['id'];
  provider: string;
  createdAt: string;
  amountTotal: number;
  amountPaid: number;
  amountPaidInCents: number;
  subscriptionId: Subscription['id'];
  userPortfolioId?: string;
  userPortfolioVersion?: number;
  editedBy?: number;
  editedAt?: string;
  currencyCode: string;
  status: string;
  paymentProviderName: string;
  affiliates: Affiliate[];
  providerData: Partial<Provider>;
  items: InvoiceItem[];
};

type Project = {
  id: number;
  amount: number;
  currency: Currency;
};

export enum CategoryStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
}

export type Category = ResponseData<ContentTypes, '/v2/categories/{id}', 'get'> & {
  plainTitle?: string;
  plainDescription?: string;
  plainHeadline?: string;
};

export type InitiativeStatistics = ResponseData<
  ContentTypes,
  '/v2/initiatives/{id}/statistics',
  'get'
>;

export type Location = ResponseData<ContentTypes, '/v2/locations/', 'get'>['result'][0];

export type LocationCountry = {
  code: Location['code'];
  country: Exclude<Location['country'], undefined>;
};

export type LocationWater = {
  code: Location['code'];
  water: Exclude<Location['water'], undefined>;
};

export type Country = ResponseData<ContentTypes, '/v2/countries/', 'get'>['result'][0];

export type Impacter = ResponseData<ContentTypes, '/v2/impacters/{impacterId}', 'get'>;

type LocalizedString = Partial<Record<Locales, string>>;

export type PricelessPlanetOptions = {
  type: 'PPC';
  color: string;
  textColor: string;
  heroImage?: string | null;
  heroOverlayPercentage?: number;
  heroColorType?: 'dark' | 'light';
  heroImageCredit?: string;
  heroImageCurrentFile?: File | null;
  partnerLogo?: string | null;
  partnerLogoCurrentFile?: File | null;
  preamble: string;
  preambleByLocale?: LocalizedString;
  title: string;
  titleByLocale?: LocalizedString;
  hideDonationModule?: boolean;
  categoryIds?: Category['id'][];
  initiativeIds?: Initiative['id'][];
  termsAndConditions?: string;
  termsAndConditionsByLocale?: LocalizedString;
  CTAText?: LocalizedString;
  CTALink?: LocalizedString;
};
export type SustainabilityOptions = {
  type: 'sustainability';
  heroImage?: string;
  heroOverlayPercentage?: number;
  heroImageHighlighted?: string;
  heroImageCurrentFile?: File | null;
  heroImageCredit?: string;
  partnerLogo?: string;
  partnerLogoCurrentFile?: File | null;
  heroPreamble?: string;
  heroTitle: string;
  bigParagraphTitle?: string;
  bigParagraphDescription?: string;
};

export type Locales = typeof LOCALES[number];

export type DonationProgram = ResponseData<
  PartnersTypes,
  '/v2/partners/{partnerId}/donation-programs/{id}',
  'get'
>;
export type DonationProgramStatus = 'ACTIVE' | 'DELETED';

export const COMMUNITY_PROGRAM_TYPES: DonationProgram['type'][] = [
  'CONSUMER_PAID_DONATION',
  'ONE_TIME_DIRECT_PAYMENT_USER_DONATION',
  'ONE_TIME_INVOICED_CONSUMER_PAID_DONATION',
];

export const CORPORATE_PROGRAM_TYPES: DonationProgram['type'][] = [
  'PARTNER_DONATION',
  'ONE_TIME_INVOICED_DONATION',
];

export type MapWidgetType = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  type: string;
  settings: {
    publicKey: string;
    oceanColor: string;
    placesColor: string;
    groundColor: string;
    headerColor: string;
    textColor: string;
    arrowColor: string;
    arrowBackgroundColor: string;
    cardColor: string;
    dotStyle: DotStyle;
  };
  version: number;
};

export enum DotStyle {
  round = 'round',
  square = 'square',
}

export type StatisticsWidgetType = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  type: string;
  settings: {
    publicKey: string;
    backgroundColor: string;
    headerColor: string;
    textColor: string;
  };
  version: number;
};

export type TickerWidgetType = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  type: string;
  settings: {
    goalAmount: number;
    progressBarColor: string;
    progressBarFillColor: string;
    publicKey: string;
    backgroundColor: string;
    headerText: string;
    currencyCode: string;
    textColor: string;
  };
  version: number;
};

export type WidgetType = MapWidgetType | StatisticsWidgetType | TickerWidgetType;

export enum WidgetTypes {
  MAP_WIDGET = 'map',
  STATISTICS_WIDGET = 'statistics',
  TICKER_WIDGET = 'ticker',
}

type SubscriptionItem = {
  id: number;
  totalAmount: number;
  type: string;
  impacterIds?: Impacter['id'][];
  categoryIds?: Category['id'][];
};

type PortfolioItem = {
  categoryId?: Category['id'];
  impacterId?: Impacter['id'];
};

enum SubscriptionStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  INCOMPLETE = 'INCOMPLETE',
  INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED',
  PAST_DUE = 'PAST_DUE',
  UNPAID = 'UNPAID',
}

export type Subscription = {
  id: number;
  userId: User['id'];
  providerReference: string;
  providerData?: Partial<Pick<Provider, 'partnerId' | 'donationProgramId' | 'giftId'>>;
  endDate: string;
  createdAt: string;
  canceledAt: string;
  paymentProviderName: string;
  status: keyof typeof SubscriptionStatus;
  amount: number;
  interval: string;
  intervalCount: number;
  currency: Currency;
  items?: SubscriptionItem[];
  portfolioItems?: PortfolioItem[];
};

export type AggregatedPartnerStatistics = ResponseData<
  AccountingTypes,
  '/v2/partners/{partnerId}/donations/statistics',
  'get'
>['result'];

enum MachineTokenStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  REVOKED = 'REVOKED',
}

export type MachineToken = {
  createdAt: string;
  donationProgramId: DonationProgram['id'];
  expires: string;
  id: number;
  jti: string;
  name: string;
  roles: UserRoles;
  shortToken?: string;
  status: keyof typeof MachineTokenStatus;
  token?: string;
};

export type PaymentRequest = ResponseData<
  AccountingTypes,
  '/v2/providers/partner/payment-requests/{id}',
  'get'
>;

export type PaymentRequestStatus = PaymentRequest['status'];

export type PartnerBillingDetails = ResponseData<
  PartnersTypes,
  '/v2/partners/{id}/billing-details',
  'get'
>;

type ShopifyWebhookRuleCondition = {
  fieldName: 'tags';
  comparison: 'isEqualTo';
  value: string;
};

export type ShopifyWebhookRule = {
  id: string;
  type: 'FIXED_AMOUNT' | 'PERCENTAGE';
  value: number;
  conditions: ShopifyWebhookRuleCondition[];
};

export type ShopifyConfiguration = {
  id: string;
  status: 'ACTIVE' | 'INACTIVE';
  partnerId: Partner['id'];
  donationProgramId: DonationProgram['id'];
  shopifyDomain: string;
  webhookHost?: string;
  donationRules: ShopifyWebhookRule[];
  APISecretKey: string;
  adminAPIAccessToken: string;
};

export type NewShopifyConfiguration = Omit<
  ShopifyConfiguration,
  'id' | 'partnerId' | 'donationProgramId'
>;

export type CategoryAllocation = ResponseData<
  AccountingTypes,
  '/v2/allocations/categories/{categoryId}',
  'get'
>[number];

export type Initiative = ResponseData<ContentTypes, '/v2/initiatives/{initiativeId}', 'get'> & {
  plainTitle?: string;
  plainTagline?: string;
  plainDescription?: string;
};

export type Approach = ResponseData<ContentTypes, '/v2/approaches/{id}', 'get'> & {
  plainTitle?: string;
};

export class FetchError extends Error {
  public type: string;
  public status: number;
  public body?: { message?: string };

  constructor(message: string, type: string, status: number, body?: { message?: string }) {
    super(message);

    this.type = type;
    this.status = status;
    this.body = body;
  }
}

export type PartnerProfile = {
  id: string;
  name: string;
  availableCategoryIds: string[];
  rootCampaignPageUrl: string;
  extraAffiliation: string | null;
};

export type UploadableImageTypes =
  | 'logo'
  | 'campaign-image'
  | 'campaign-page-hero-image'
  | 'campaign-page-partner-logo';

export type PostType = 'EDITORIAL' | 'IMPACTER' | 'INITIATIVE';
export type PostStatus = 'DELETED' | 'DRAFT' | 'PENDING' | 'PUBLISHED' | 'SCHEDULED';
export type VideoResource = {
  mimeType: string;
  orientation?: 'landscape' | 'portrait';
  src: string;
};
export type PostAsset = {
  id: number | string; // video id or image id
  impacterId: string;
  type: 'IMAGE' | 'VIDEO' | 'TEXT' | 'DOCUMENT';
  video?: {
    key: string;
    resources: VideoResource[];
    posters: { main?: string; animation?: string; firstFrame?: string; mainThumbnail?: string };
    metaData: {
      ratio?: number;
      durationInMs?: number;
      hasAudioTrack?: boolean;
      orientation?: 'landscape' | 'portrait';
      originalHeight?: number;
      originalWidth?: number;
      originalMimeType?: string;
    };
    status?: 'PUBLISHED' | 'PENDING PUBLISHING' | 'SCHEDULED';
  };
  image?: {
    key: string;
    src: string;
    metaData: { originalMimeType: string; originalHeight?: number; originalWidth?: number };
    thumbnailSrc?: string;
  };
  document?: {
    key: string;
    id: string;
    posters?: {
      main?: string;
      thumbnailSrc?: string;
    };
    metaData: {
      originalName: string;
      originalMimeType: string;
      sizeInBytes: number | null;
    };
    poster: {
      src: string | null;
      thumbnailSrc: string | null;
    };
  };
};

export type Post = ResponseData<ContentTypes, '/v2/posts/{postId}', 'get'>;

export const AssetTypes = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  DOCUMENT: 'DOCUMENT',
} as const;
export type AssetType = typeof AssetTypes[keyof typeof AssetTypes];

export function isAssetType(type?: string | null): type is AssetType {
  return Object.values(AssetTypes).includes(type as never);
}

export type Asset = ResponseData<ContentTypes, '/v2/assets/{assetId}', 'get'>;

export type Video = ResponseData<ContentTypes, '/v2/videos/{id}', 'get'>;

export const ImpactFundsTabs = {
  about: 'about',
  initiatives: 'initiatives',
  media: 'media',
  updates: 'updates',
  resources: 'resources',
  funding: 'funding',
} as const;
export type ImpactFundsTab = typeof ImpactFundsTabs[keyof typeof ImpactFundsTabs];

export function isImpactFundsTab(type?: string | null): type is ImpactFundsTab {
  return Object.values(ImpactFundsTabs).includes(type as never);
}

export const InitiativeTabs = {
  about: 'about',
  updates: 'updates',
  media: 'media',
  resources: 'resources',
} as const;
export type InitiativeTab = typeof InitiativeTabs[keyof typeof InitiativeTabs];

export function isInitiativeTab(type?: string | null): type is InitiativeTab {
  return Object.values(InitiativeTabs).includes(type as never);
}

export type FeaturedArticleType = Entry<TypeBlogPostSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>;

export function isFeaturedArticle(
  featuredArticle?: FeaturedArticleType,
): featuredArticle is FeaturedArticleType {
  return !!featuredArticle;
}

export type Profile = Entry<TypeProfileSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>;

export type Pillar = ResponseData<ContentTypes, '/v2/pillars/', 'get'>['result'][number] & {
  plainTitle?: string;
};

export type ContributionRow = PaymentRequest & {
  streamName: string;
  type: string;
  formattedStatus: string;
  statusColor: string;
  fundNames: string;
  amount: string;
  formattedDate: string;
};
