import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import AppCrash from './components/AppCrash';
import appConfig from './config/config';
import { AuthProvider } from './context/AuthContext';
import Theme from './theme';
import { CookiesProvider } from 'react-cookie';
import TrackingConsent from './components/TrackingConsent';
import { CssBaseline } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';

if (appConfig.env !== 'dev' && appConfig.env !== 'dev-staging') {
  Sentry.init({
    dsn: 'https://ee108e6a28e34011ba9bab6f17a430ae@o249704.ingest.sentry.io/5238989',
    environment: appConfig.env,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', appConfig.apiUrl, /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
    release: 'alliance@0.0.1',
  });
}

function RouterProvider({ children }: { children: React.ReactNode }) {
  return (
    <BrowserRouter>
      <AuthProvider>
        <TrackingConsent />
        {children}
      </AuthProvider>
    </BrowserRouter>
  );
}

function AppProviders() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <Sentry.ErrorBoundary
          fallback={({ error, componentStack }) => (
            <AppCrash error={error} componentStack={componentStack} />
          )}
        >
          <HelmetProvider>
            <CookiesProvider>
              <RouterProvider>
                <App />
              </RouterProvider>
            </CookiesProvider>
          </HelmetProvider>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default AppProviders;
