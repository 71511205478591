import React, { useEffect, Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useAuth } from './context/AuthContext';
import useQuery from './hooks/useQuery';
import FullPageSpinner from './components/FullPageSpinner';
import { Container } from '@mui/material';
import { FetchError } from './types/entities';

const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'));
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

const MAX_RETRIES = 6;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // turn off default 3 retries that may cause timeouts in test envs
      retry: (failureCount, error) => {
        if (process.env.NODE_ENV === 'test') {
          return false;
        }

        if (failureCount > MAX_RETRIES) {
          return false;
        }

        if (error instanceof FetchError && HTTP_STATUS_TO_NOT_RETRY.includes(error.status)) {
          // eslint-disable-next-line no-console
          console.log(`Aborting retry due to ${error.status} status`);
          return false;
        }

        return true;
      },
    },
  },
});

const App = () => {
  const { user } = useAuth();

  const query = useQuery();

  useEffect(() => {
    const redirectUrl = query.get('redirectUrl');
    if (redirectUrl) {
      sessionStorage.setItem('redirectUrl', redirectUrl);
    }
  }, [query]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Suspense
          fallback={
            <Container sx={{ padding: 0, height: '100vh' }}>
              <FullPageSpinner />
            </Container>
          }
        >
          <div>{user ? <AuthenticatedApp /> : <UnauthenticatedApp />}</div>
        </Suspense>
      </QueryClientProvider>
    </>
  );
};

export default App;
