import config from '../../config';
import { MachineToken } from '../../types/entities';
import { galaxyFetcher, handleErrors } from '../api';
import { apiKeyName } from '../constants';

export async function getJWTToken(firebaseToken: string) {
  const url = `${config.apiUrl}/authentication/v2/firebase?access_token=${firebaseToken}&skipUserCreation=true`;

  const response = await fetch(url);

  if (response.status === 401) {
    const body = await response.json();
    if (body && body.code == 103) {
      throw new Error('missing_email');
    }

    if (body && body.code == 106) {
      throw new Error(body.message);
    }

    throw new Error('Client error: 401 Unauthorized');
  }

  if (response.status === 404) {
    throw new Error('User not found');
  }

  if (!response.ok) {
    await handleErrors(response);
  }

  return await response.json();
}

export async function getMachineTokens(partnerId: string): Promise<{ result: MachineToken[] }> {
  return await galaxyFetcher(`/authentication/v2/machine-tokens`, {
    query: {
      partnerId,
    },
  });
}

export async function createMachineTokens(
  partnerId: string,
  partnerName: string,
  donationProgramId?: string,
): Promise<Pick<MachineToken, 'id' | 'token' | 'shortToken'>> {
  return await galaxyFetcher(`/authentication/v2/machine-tokens`, {
    method: 'POST',
    body: JSON.stringify({
      name: `${apiKeyName}-${partnerName}`,
      partnerId,
      roles: ['PARTNER_API'],
      donationProgramId,
    }),
  });
}

export async function deleteMachineTokens(tokenId: MachineToken['id']): Promise<void> {
  return await galaxyFetcher(`/authentication/v2/machine-tokens/${tokenId}`, {
    method: 'DELETE',
  });
}
