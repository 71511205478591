import { Helmet } from 'react-helmet-async';
import { useCookies } from 'react-cookie';
import { useAuth } from '../../context/AuthContext';

//TODO: Add GTAGS.STAGING when set up in GTAGMANAGER
const G_TAGS = {
  PRODUCTION: 'G-JWL8KEC593',
};

const GoogleAnalytics = () => {
  const { user } = useAuth();
  const [cookies] = useCookies(['cookie_consent']);
  const id = user?.id;
  const tagId = G_TAGS.PRODUCTION;

  return cookies.cookie_consent ? (
    <Helmet>
      {/* <!-- Google tag (gtag.js) --> */}
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${tagId}`}></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          if(${id} === undefined) {
            gtag('config', '${tagId}');
          } else {
            gtag('config', '${tagId}', {user_id: ${id}})
          }
        `}
      </script>
    </Helmet>
  ) : null;
};

export default GoogleAnalytics;
