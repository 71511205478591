export const extendedPalette: { [key: string]: { [key: number]: string } } = {
  orange: {
    100: '#FFB679',
    200: '#FFA357',
    300: '#FF8D3B',
    400: '#FE811A',
    500: '#FD7709',
    600: '#EF6C00',
  },
  red: {
    100: '#FFADA4',
    200: '#FF9A8E',
    300: '#FF6B67',
    400: '#FF5353',
    500: '#E22F35',
    600: '#BA1A20',
  },
  green: {
    100: '#8EE9C5',
    200: '#3FCF9B',
    300: '#29BE88',
    400: '#196356',
    500: '#005045',
    600: '#00382F',
  },
  purple: {
    100: '#D9B9FF',
    200: '#BF95F0',
    300: '#8560DB',
    400: '#663FBA',
    500: '#5329A7',
    600: '#3C0090',
  },
  yellow: {
    100: '#FFDB93',
    200: '#FFD27B',
    300: '#FFC453',
    400: '#FFBA35',
    500: '#FFAE11',
    600: '#F09E00',
  },
  grey: {
    50: '#FAFAFA',
    100: '#F5F5F5', // Grey 01
    200: '#E5E5E5', // Grey 02
    300: '#CACACA', // Grey 03
    400: '#373737', // Grey 04
  },
};
