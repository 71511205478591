import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export default function useQuery() {
  const location = useLocation();
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  return query;
}
