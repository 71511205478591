import { jwtDecode } from 'jwt-decode';

const KEY = '@Auth:Token';
let jwtToken: string | null;

export const getToken = () => {
  if (jwtToken) {
    return jwtToken;
  }

  jwtToken = sessionStorage.getItem(KEY);
  return jwtToken;
};

export const setToken = (token: string) => {
  jwtToken = token;

  sessionStorage.setItem(KEY, token);
};

export const removeToken = () => {
  jwtToken = null;

  sessionStorage.removeItem(KEY);
};

export const getCurrentUserRoles = () => {
  const token = getToken();
  if (!token) {
    return {};
  }

  return jwtDecode<{ roles?: { PARTNER_ADMIN?: boolean; ADMIN?: boolean; IMPACTER?: boolean } }>(
    token,
  ).roles;
};
