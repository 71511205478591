import { DotStyle, Locales, PartnerProfile } from '../types/entities';

export const apiKeyName = 'PARTNER_INTEGRATIONS_API_TOKEN';

export const dotStyleValues = {
  [DotStyle.round]: { dotSize: 3, dotRadius: 3 },
  [DotStyle.square]: { dotSize: 3, dotRadius: 0 },
};

export const PROFILE_ID_MAPPING = {
  DEFAULT: 'default',
  DEMO: 'demo',
  PANGAIA: 'pangaia',
  KLARNA: 'klarna',
  PPC: 'mastercard-ppc',
} as const;

export const LOCALES = ['en', 'fr', 'de', 'no', 'pt', 'es', 'sv'] as const;
export const LOCALE_NAMES: Record<Locales, string> = {
  en: 'English',
  fr: 'French',
  de: 'German',
  no: 'Norweigan',
  pt: 'Portuguese',
  es: 'Spanish',
  sv: 'Swedish',
};

const PROFILE_WITH_SUSTAINABILITY_PAGE_SUPPORT: PartnerProfile['id'][] = [
  PROFILE_ID_MAPPING.DEFAULT,
  PROFILE_ID_MAPPING.PANGAIA,
  PROFILE_ID_MAPPING.DEMO,
];

export function hasSustainabilityPageSupport(profile: PartnerProfile) {
  return PROFILE_WITH_SUSTAINABILITY_PAGE_SUPPORT.includes(profile.id);
}
