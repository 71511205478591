import { Box } from '@mui/material';
import MilkywireLogo from './MilkywireLogo';

const loaderStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',

  '@keyframes beat': {
    'from, 20%, 40%, to': {
      width: '90%',
    },
    '10%, 30%': {
      width: '100%',
    },
  },

  '@keyframes fadeInDelayed': {
    '0': {
      opacity: '0',
    },
    '10%': {
      opacity: '0',
    },
    '100': {
      opacity: '1',
    },
  },
};

type Props = { overlay?: boolean; className?: string };

const FullPageSpinner = ({ className }: Props) => (
  <Box data-testid="loader" className={className} sx={loaderStyles}>
    <Box
      sx={{
        position: 'relative',
        width: { xs: '20%', sm: '7vw' },
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <MilkywireLogo color="#111111" size="100%" style={{ animation: 'beat 1s linear infinite' }} />
    </Box>
  </Box>
);

export default FullPageSpinner;
