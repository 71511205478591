import { createTheme } from '@mui/material/styles';
import { TypeText } from '@mui/material/styles/createPalette';
import { extendedPalette } from './extendedPalette';

// this is needed to override the "grey" color of mui, which otherwise only allows number indexing
declare module '@mui/material' {
  interface Color {
    dark: string;
    main: string;
    light: string;
  }
}
declare module '@mui/material/styles' {
  interface TypographyVariants {
    display?: React.CSSProperties;
    code?: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    display?: React.CSSProperties;
    code?: React.CSSProperties;
  }

  interface Palette {
    orange: PaletteColor;
    red: PaletteColor;
    yellow: PaletteColor;
    green: PaletteColor;
    purple: PaletteColor;
    textLight: TypeText;
  }

  interface PaletteOptions {
    orange: PaletteColor;
    red: PaletteColor;
    yellow: PaletteColor;
    green: PaletteColor;
    purple: PaletteColor;
    textLight: TypeText;
  }

  interface PaletteColor {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
    state?: {
      selected: string;
      hover: string;
      focusVisible: string;
      outlinedBorder: string;
    };
  }

  interface TypeBackground {
    light: string;
    default: string;
    paper: string;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display: true;
    code: true;
    detail1: true;
    detail2: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    purple: true;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    textLight: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    green: true;
    grey: true;
    transparent: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    grey: true;
    orange: true;
    red: true;
    yellow: true;
    green: true;
    purple: true;
    transparent: true;
  }
}

const black = '#111111';
const white = '#FFFFFF';
const getTransparentBlack = (fraction: number) => `rgba(17, 17, 17, ${fraction})`;
const getTransparentWhite = (fraction: number) => `rgba(255, 255, 255, ${fraction})`;

// NOTE: Separated so that we can use the colors in helper functions
export const palette = {
  common: {
    black,
    white,
  },
  background: {
    light: extendedPalette.grey[50],
    default: extendedPalette.grey[100],
    paper: white,
  },
  primary: {
    dark: extendedPalette.purple[600],
    main: extendedPalette.purple[400],
    light: extendedPalette.purple[200],
    contrastText: white,
  },
  secondary: {
    dark: extendedPalette.green[600],
    main: extendedPalette.green[400],
    light: extendedPalette.green[200],
    contrastText: white,
  },
  orange: {
    dark: extendedPalette.orange[600],
    main: extendedPalette.orange[400],
    light: extendedPalette.orange[200],
    contrastText: white,
  },
  red: {
    dark: extendedPalette.red[600],
    main: extendedPalette.red[400],
    light: extendedPalette.red[200],
    contrastText: white,
  },
  green: {
    dark: extendedPalette.green[600],
    main: extendedPalette.green[400],
    light: extendedPalette.green[200],
    contrastText: white,
  },
  purple: {
    dark: extendedPalette.purple[600],
    main: extendedPalette.purple[400],
    light: extendedPalette.purple[200],
    contrastText: white,
  },
  yellow: {
    dark: extendedPalette.yellow[600],
    main: extendedPalette.yellow[400],
    light: extendedPalette.yellow[200],
    contrastText: white,
  },
  grey: {
    dark: extendedPalette.grey[400],
    main: extendedPalette.grey[300],
    light: extendedPalette.grey[200],
    contrastText: black,
  },
  transparent: {
    dark: extendedPalette.grey[300],
    main: getTransparentWhite(0),
    light: getTransparentWhite(0),
    contrastText: black,
  },
  error: {
    dark: extendedPalette.red[600],
    main: extendedPalette.red[500],
    light: extendedPalette.red[300],
    contrastText: white,
  },
  warning: {
    dark: extendedPalette.orange[600],
    main: extendedPalette.orange[400],
    light: extendedPalette.orange[200],
    contrastText: white,
  },
  info: {
    state: {
      selected: '#F2EEF8',
      hover: '#F8F7FC',
      focusVisible: '#CBBEE4',
      outlinedBorder: '#A994D3',
    },
    dark: extendedPalette.purple[600],
    main: extendedPalette.purple[500],
    light: extendedPalette.purple[300],
    contrastText: white,
  },
  success: {
    dark: extendedPalette.green[500],
    main: extendedPalette.green[300],
    light: extendedPalette.green[200],
    contrastText: white,
  },
  text: {
    primary: black,
    secondary: getTransparentBlack(0.8),
    disabled: getTransparentBlack(0.5),
  },
  textLight: {
    primary: white,
    secondary: getTransparentWhite(0.7),
    disabled: getTransparentWhite(0.5),
  },
  action: {
    active: '#0000008A', // 54%
    hover: '#0000000A', // 4%
    selected: '#00000014', // 8%
    disabled: '#0000007A', // 48%
    disabledBackground: '#0000001F', // 12%
    focus: '#0000001F', // 12%
  },
  tooltip: {
    fill: '#616161E5', // 90%;
  },
  divider: '#0000001F', // 12%
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960, // NOTE: this value diverts from the standard which is 900px
    lg: 1200,
    xl: 1536,
  },
};

// index:               0  1  2   3   4   5   6   7   8   9   10
export const spacing = [4, 8, 12, 16, 24, 32, 40, 48, 56, 64, 80];

export const overrides = () => {
  return {
    /**
     * KH Teka Light = fontWeight: 300
     * KH Teka Regular = fontWeight: 400
     * KH Teka Medium = fontWeight: 500
     */
    typography: {
      fontFamily: ['KH Teka', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      h1: {
        fontSize: '40px',
        lineHeight: '48px',
        letterSpacing: '-0.025em',
        fontWeight: '300',

        [theme.breakpoints.down('md')]: {
          fontSize: '32px',
          lineHeight: '38px',
          letterSpacing: '-0.02em',
        },
      },
      h2: {
        fontSize: '32px',
        lineHeight: '38px',
        letterSpacing: '-0.02em',
        fontWeight: '300',

        [theme.breakpoints.down('md')]: {
          fontSize: '28px',
          lineHeight: '32px',
          letterSpacing: '-0.02em',
        },
      },
      h3: {
        fontSize: '28px',
        lineHeight: '32px',
        letterSpacing: '-0.02em',
        fontWeight: '300',

        [theme.breakpoints.down('md')]: {
          fontSize: '24px',
          lineHeight: '28px',
        },
      },
      h4: {
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: '300',
        letterSpacing: '-0.01em',

        [theme.breakpoints.down('md')]: {
          fontSize: '20px',
          lineHeight: '26px',
          letterSpacing: '-0.015em',
        },
      },
      h5: {
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: '300',
        letterSpacing: '-0.01em',
      },
      h6: {
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: '300',
        letterSpacing: '-0.005em',
      },
      subtitle1: {
        fontWeight: '300',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '-0.01em',
      },
      subtitle2: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
      },
      body1: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      body2: {
        fontSize: '14px',
        lineHeight: '20px',
      },
      caption: {
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
      },
      overline: {
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '16px',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
        color: theme.palette.text.disabled,
      },
      display: {
        fontSize: '48px',
        lineHeight: '56px',
        letterSpacing: '-0.03em',
      },
      code: {
        fontFamily: ['Monaco', 'Consolas'].join(','),
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 400,
        letterSpacing: '0.05em',
        whiteSpace: 'pre-wrap',
      },
      detail1: {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.04em',
      },
      detail2: {
        fontSize: '10px',
        lineHeight: '14px',
        letterSpacing: '0.02em',
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: 'KH Teka',
          },
          overline: {
            display: 'block',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            '&:last-child': {
              borderRadius: 0,
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: '24px 0 24px 24px',
            minHeight: '0px',
            margin: 0,
            '& .MuiAccordionSummary-content': {
              gap: '8px',
              margin: 0,
              '&.Mui-expanded': {
                minHeight: '0px',
                margin: '0px',
              },
            },
            '& .MuiAccordionSummary-expandIconWrapper': {
              padding: '0px 24px',
            },
            '& .MuiAccordionSummary-contentGutters': {
              margin: 0,
              alignItems: 'center',
            },
            '&.Mui-expanded': {
              minHeight: '0px',
              margin: '0px',
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
            padding: '8px 32px 32px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: 'KH Teka',
            borderRadius: 0,
            textTransform: 'unset',
            boxShadow: 'none',

            '&:hover': {
              boxShadow: 'none',
            },
          },
          sizeLarge: {
            height: '72px',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 400,
            padding: '24px',
          },
          sizeMedium: {
            height: '48px',
            padding: '10px 16px',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 500,
          },
          sizeSmall: {
            border: 'none',
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '20px',
            textTransform: 'uppercase',
            letterSpacing: '0.1em',
          },
          endIcon: {
            margin: 0,
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            textTransform: 'unset',
            boxShadow: 'none',
          },
        },
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            fontFamily: 'KH Teka',
            textTransform: 'unset',
            boxShadow: 'none',

            '&:hover': {
              boxShadow: 'none',
            },
          },
          sizeMedium: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 400,
            padding: '12px 24px',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '20px',
            textTransform: 'uppercase',
            letterSpacing: '0.1em',
            padding: '8px 16px',
          },
          label: {
            padding: 0,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            textTransform: 'unset',
            boxShadow: 'none',
            color: theme.palette.common.black,
            borderRadius: `${spacing[0]}px`,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            '&.Mui-selected': {
              backgroundColor: theme.palette.action.focus,
            },
            '&.Mui-selected:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            lineHeight: '24px',
          },
        },
        defaultProps: {
          variant: 'outlined',
          size: 'small',
          margin: 'dense',
          color: 'info',
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            '& .MuiTabs-indicatorSpan': {
              maxWidth: 'calc(100% - 48px)',
              width: '100%',
              backgroundColor: theme.palette.info.main,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: 'unset',
            padding: '12px 24px 0 24px',
            color: theme.palette.text.primary,
            textTransform: 'none',
            '&.Mui-selected': {
              color: theme.palette.info.main,
            },
          },
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          root: {
            padding: '0',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            backgroundColor: theme.palette.common.white,
            color: theme.palette.text.primary,

            [theme.breakpoints.up('md')]: {
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            fontSize: '10px',
            lineHeight: '20px',
            textTransform: 'uppercase',
            letterSpacing: '0.1em',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            boxShadow: 'none',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '4px',
          },
          list: {
            padding: '0px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: 0,
            padding: '4px 16px',
          },
          dense: {
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: 0,
          },
        },
        defaultProps: {
          dense: true,
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: theme.palette.text.primary,
            textDecorationColor: theme.palette.text.primary,
            textUnderlineOffset: '2px',
            textDecorationThickness: '1px',
            '&:hover': {
              textDecorationColor: theme.palette.purple.main,
            },
          },
        },
        variants: [
          {
            props: { underline: 'none' },
            style: {
              ':hover': {
                opacity: 0.8,
              },
            },
          },
        ],
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: theme.palette.grey.main,
            opacity: 1,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: 'KH Teka',
            fontSize: '14px',
            lineHeight: '24px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.paper,
          },
          input: {
            height: 'auto',
          },
          notchedOutline: {
            legend: {
              fontSize: '0.85em',
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderRadius: '0px',
            backgroundColor: theme.palette.background.paper,
            '&.Mui-disabled': {
              backgroundColor: theme.palette.background.paper,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: '10px',
            lineHeight: 1,
            letterSpacing: '0.4px',
            textTransform: 'unset',
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            width: '40px',
            height: '40px',
            borderRadius: 0,
            '&.Mui-selected': {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.textLight.primary,
            },
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            border: `1px solid ${theme.palette.divider}`,
            borderBottom: 'none',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '16px 0 16px 24px',
            fontFamily: 'KH Teka',
            fontSize: '14px',
            lineHeight: '20px',
          },
          head: {
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: '300',
            letterSpacing: '-0.005em',
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            border: `1px solid ${theme.palette.divider}`,
            borderTop: 'none',
            backgroundColor: theme.palette.info.state?.selected,
            color: theme.palette.text.primary,
          },
          toolbar: {
            padding: 0,
            backgroundColor: theme.palette.info.state?.selected,
            '@media (min-width: 600px)': {
              padding: 0,
            },

            '.MuiTablePagination-select': {
              display: 'flex',
              alignItems: 'center',
            },
          },
          actions: {
            padding: '8px',
            backgroundColor: theme.palette.info.light,
            color: theme.palette.textLight.primary,
            '.Mui-disabled': {
              color: theme.palette.textLight.disabled,
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.light,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:hover': {
              ':hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '10px',
            lineHeight: '14px',
            letterSpacing: '0.02em',
          },
        },
      },
    },
  };
};

let theme = createTheme({ palette, breakpoints });
theme = createTheme(theme, overrides());

export default theme;
