import { client } from '../galaxyClient';

export async function getCurrentUser() {
  const { data } = await client.users.GET('/v2/me/', {});
  return data;
}

export async function getFeedbackSegment() {
  const { data } = await client.users.GET('/v2/feedback/segments/', {});
  return data;
}

export async function sendFeedback({
  id,
  ...body
}: {
  id: string;
  comment: string;
  rating: number;
  pickedSuggestion?: string;
}) {
  await client.users.POST('/v2/feedback/{segmentId}', {
    params: {
      path: {
        segmentId: id,
      },
    },
    body,
  });
}
