import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { FirebaseApp, FirebaseError, getApps, initializeApp } from 'firebase/app';
import {
  User as FirebaseUser,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  getRedirectResult,
  isSignInWithEmailLink,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { captureException, setUser as sentrySetUser } from '@sentry/react';
import { Container } from '@mui/material';
import FullPageSpinner from '../components/FullPageSpinner';
import config from '../config/config';
import { getCurrentUser } from '../lib/api/users';
import { getJWTToken } from '../lib/api/authentication';
import { setToken, removeToken, getCurrentUserRoles } from '../lib/token';
import { CurrentUser } from '../types/entities';
import useQuery from '../hooks/useQuery';
import AlertSnackbar from '../components/AlertSnackbar';

let firebaseApp: FirebaseApp;
try {
  const apps = getApps();
  if (!apps || !apps.length) {
    firebaseApp = initializeApp(config.firebase);
  }
} catch (error) {
  captureException(error);
}

export type AuthContext = {
  user?: CurrentUser;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, password: string) => Promise<FirebaseUser | null>;
  resetPassword: (email: string) => Promise<void>;
  updateTokenAndUser: () => Promise<void>;
  googleLogin: () => void;
  emailLinkLogin: (email: string) => Promise<void>;
};

const Context = createContext<AuthContext | null>(null);
type Props = {
  mockValues?: Partial<AuthContext>;
  children: React.ReactNode;
  values?: Partial<AuthContext>;
};
function AuthProvider(props: Props) {
  const navigate = useNavigate();
  const query = useQuery();

  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [user, setUser] = useState<CurrentUser>();
  const [isLoginError, setIsLoginError] = useState(false);

  const logout = useCallback(async () => {
    await signOut(getAuth(firebaseApp));
    setUser(undefined);
    removeToken();
    localStorage.removeItem('selectedPartnerId');

    navigate('/');
  }, [navigate]);

  const updateTokenAndUser = useCallback(async () => {
    const firebaseUser = getAuth(firebaseApp).currentUser;
    if (firebaseUser) {
      const idTokenResponse = await firebaseUser.getIdTokenResult(true);

      let token;
      try {
        const res = await getJWTToken(idTokenResponse.token);
        token = res.token;
      } catch (error) {
        if (error instanceof Error && error.message === 'User not found') {
          await logout();
          setIsLoginError(true);
          throw error;
        } else {
          throw error;
        }
      }

      setToken(token);
      const roles = getCurrentUserRoles();
      if (roles?.PARTNER_ADMIN || roles?.ADMIN) {
        const user = await getCurrentUser();
        setUser(user);
        sentrySetUser({ id: user.id.toString() });
        setIsLoadingUser(false);

        const redirectUrl = sessionStorage.getItem('redirectUrl') ?? query.get('redirectUrl');
        if (redirectUrl) {
          sessionStorage.removeItem('redirectUrl');
          navigate(redirectUrl);
        }
      } else if (roles?.IMPACTER) {
        await logout();
        sentrySetUser(null);
        navigate('/no-access?role=impacter');
        setIsLoadingUser(false);
      } else {
        await logout();
        sentrySetUser(null);
        navigate('/no-access');
        setIsLoadingUser(false);
      }
    } else {
      setIsLoadingUser(false);
    }
  }, [logout, navigate, query]);

  const checkRedirectResult = useCallback(async () => {
    try {
      await getRedirectResult(getAuth(firebaseApp));
      await checkEmailLink();
      await updateTokenAndUser();
    } catch (error) {
      captureException(error);
      setIsLoadingUser(false);
    }
  }, [updateTokenAndUser]);

  useEffect(() => {
    checkRedirectResult();
  }, [checkRedirectResult]);

  async function checkEmailLink() {
    if (!isSignInWithEmailLink(getAuth(firebaseApp), window.location.href)) {
      return;
    }

    const email = localStorage.getItem('emailForSignIn');
    if (!email) {
      return;
    }

    try {
      await signInWithEmailLink(getAuth(firebaseApp), email, window.location.href);
      localStorage.removeItem('emailForSignIn');
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw error.code;
      } else {
        captureException(error);
      }
    }
  }

  const login = async (email: string, password: string) => {
    await signInWithEmailAndPassword(getAuth(firebaseApp), email, password);
    await updateTokenAndUser();
  };

  const register = async (email: string, password: string) => {
    await createUserWithEmailAndPassword(getAuth(firebaseApp), email, password);
    return getAuth(firebaseApp).currentUser;
  };

  const resetPassword = (email: string) => {
    return sendPasswordResetEmail(getAuth(firebaseApp), email, {
      url: `${window.location.origin}/login?email=${email}`,
    });
  };

  const googleLogin = () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    signInWithRedirect(getAuth(firebaseApp), provider);
  };

  const emailLinkLogin = async (email: string) => {
    const settings = {
      url: `${window.location.href}`,
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(getAuth(firebaseApp), email, settings);
      localStorage.setItem('emailForSignIn', email);
    } catch (error) {
      captureException(error);
    }
  };

  if (isLoadingUser) {
    return (
      <Container
        sx={{
          bgcolor: 'background.default',
          padding: 0,
          height: '100vh',
        }}
      >
        <FullPageSpinner />
      </Container>
    );
  }

  return (
    <Context.Provider
      value={{
        user,
        login,
        logout,
        register,
        resetPassword,
        updateTokenAndUser,
        googleLogin,
        emailLinkLogin,
      }}
    >
      {props.children}
      <AlertSnackbar
        open={isLoginError}
        severity="error"
        content="No user found using this email address."
        onClose={() => setIsLoginError(false)}
      />
    </Context.Provider>
  );
}

function useAuth(): AuthContext {
  const context = useContext(Context);
  if (context === null) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}

export { AuthProvider, useAuth };
