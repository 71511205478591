import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertColor } from '@mui/material/Alert';

type AlertProps = Pick<AlertSnackbarProps, 'onClose' | 'severity'> & {
  children: React.ReactNode;
};
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type AlertSnackbarProps = {
  content: string;
  severity: AlertColor;
  onClose?: () => void;
  open: boolean;
};
export default function AlertSnackbar({ content, onClose, severity, open }: AlertSnackbarProps) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity}>
        {content}
      </Alert>
    </Snackbar>
  );
}
